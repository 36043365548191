@import 'components/mixins.scss';
@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css);

@font-face {
  font-family: 'NanumSquareNeo';
  font-weight: 100;
  src: url('/resources/fonts/nanum-square-neo/eot/NanumSquareNeoTTF-aLt.eot') format('eot'),
    url('/resources/fonts/nanum-square-neo/woff/NanumSquareNeoTTF-aLt.woff') format('woff'),
    url('/resources/fonts/nanum-square-neo/woff2/NanumSquareNeoTTF-aLt.woff2') format('woff2');
}

@font-face {
  font-family: 'NanumSquareNeo';
  font-weight: 300;
  src: url('/resources/fonts/nanum-square-neo/eot/NanumSquareNeoTTF-bRg.eot') format('eot'),
    url('/resources/fonts/nanum-square-neo/woff/NanumSquareNeoTTF-bRg.woff') format('woff'),
    url('/resources/fonts/nanum-square-neo/woff2/NanumSquareNeoTTF-bRg.woff2') format('woff2');
}

@font-face {
  font-family: 'NanumSquareNeo';
  font-weight: 500;
  src: url('/resources/fonts/nanum-square-neo/eot/NanumSquareNeoTTF-cBd.eot') format('eot'),
    url('/resources/fonts/nanum-square-neo/woff/NanumSquareNeoTTF-cBd.woff') format('woff'),
    url('/resources/fonts/nanum-square-neo/woff2/NanumSquareNeoTTF-cBd.woff2') format('woff2');
}

@font-face {
  font-family: 'NanumSquareNeo';
  font-weight: 700;
  src: url('/resources/fonts/nanum-square-neo/eot/NanumSquareNeoTTF-dEb.eot') format('eot'),
    url('/resources/fonts/nanum-square-neo/woff/NanumSquareNeoTTF-dEb.woff') format('woff'),
    url('/resources/fonts/nanum-square-neo/woff2/NanumSquareNeoTTF-dEb.woff2') format('woff2');
}

@font-face {
  font-family: 'NanumSquareNeo';
  font-weight: 900;
  src: url('/resources/fonts/nanum-square-neo/eot/NanumSquareNeoTTF-eHv.eot') format('eot'),
    url('/resources/fonts/nanum-square-neo/woff/NanumSquareNeoTTF-eHv.woff') format('woff'),
    url('/resources/fonts/nanum-square-neo/woff2/NanumSquareNeoTTF-eHv.woff2') format('woff2');
}
