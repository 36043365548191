// Colors
$white: #fff;
$black: #000;
$blue: #0091da;
$blue-light: #0091da;
$blue-dark: #0e0e24;
$gray-1: #e5e9f5;
$gray-1-5: #f3f2f4;
$gray-2: #cdcdcd;
$gray-3: #b4b7b7;
$gray-4: #727596;
$gray-5: #4a5048;
$gray-6: #342e62;
$yellow: #fff579;
$orange: #e69059;
$red: #ef2d56;
$red-dark: #b92142;
$pink: #fe5874;
$violet: #cb77a6;
$purple: #a011b1;
$purple-light: #8c6af2;
$purple-dark: #61338e;
$green: #2d5936;
$dark-green: #096134;
$light-green: #349f68;

$main-gradient: linear-gradient(233deg, $orange, $white);
$blue-gradient: linear-gradient(233deg, $white, $orange);

$body-background: $black;

// $topbar-background: $black;
$topbar-background: rgba(0, 0, 0, 0);
$topbar-text: $white;
$handler-color: $white;
$handler-background: rgba(0, 0, 0, 0.2);
// $handler-background: $white;

$global-main-content-margin-top: 0;
$global-main-content-margin-bottom: 150px;

// $footer-background: rgba(0, 0, 0, 0);
$footer-background: $blue-dark;
$footer-color: $white;
$footer-hover-color: $white;
$footer-border: transparent;

$text: $white;
$border: $gray-6;

$menu-is-transparent: true;
$menu-align-right: true;
$menu-background-color: rgba(0, 0, 0, 0);
// $menu-background-color: $black;
$menu-color: $white;
$menu: $white;
$menu-hover: $white;
$menu-selected: $white;

$menu-left-background: $white;
$menu-left-color: $black;
$menu-left-item-color-hover: $purple-light;
$menu-left-item-background-hover: $gray-1;

$menu-left-submenu-title: $black;
$menu-left-submenu-title-hover: $purple-light;
$menu-left-submenu-group-background: $gray-1;
$menu-left-submenu-opened-color: $purple-light;
$menu-left-selected-color: $white;
$menu-left-selected-background: $gray-1;

$global-background-enabled: true;
$global-background-image: url('/resources/images/background.jpg');
$global-background-image-mobile: url('/resources/images/background_m.jpg');

$global-background-repeat: no-repeat;
$global-background-color: $blue-dark;
$global-background-size: cover;

$introduce-title: $white;
$introduce-background: transparent;

$program-schedule-title-color: $white;
$program-column-background: transparent;
$program-column-color: $purple;
$program-column-font-weight: 700;
$program-border: $gray-6;
$program-body-background: transparent;
$program-body-background-odd: transparent;
$program-body-color: $white;

$program-span-background: $gray-2;
$program-span-color: $black;
$program-break-background: $gray-6;
$program-break-color: $white;
$program-openingclosing-background: $gray-6;
$program-openingclosing-color: $white;
$program-current-background: $purple;
$program-current-color: $white;
$program-font-size: rem(14);
$program-font-weight: 500;
$program-font-size-sm: rem(14);
$program-subject-font-size: rem(14);
$program-subject-font-weight: 500;
$program-subject-font-size-sm: rem(14);
$program-speaker-font-size: rem(14);
$program-speaker-font-size-sm: rem(14);
$program-speaker-font-weight: 500;
$program-time-font-size: rem(13);
$program-time-font-size-sm: rem(13);
$program-time-font-weight: 500;

// Accent colors
$default: $gray-4;
$primary: $purple-light;
$secondary: $purple-dark;
$success: $purple-dark;
$info: $red-dark;
$warning: $purple;
$danger: $red;
$light: $gray-1;
$dark: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;

// Font Family
// $base-font-family: 'Noto Sans KR', sans-serif;
// $base-font-family: 'Poppins', sans-serif;
// $base-font-family: 'Gothic A1', sans-serif;
// $base-font-family: 'Gmarket Sans', sans-serif;
// $base-font-family: 'S-Core', sans-serif;
// $base-font-family: 'Mukta', sans-serif;
// $base-font-family: 'NanumSquare', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
// 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
// sans-serif;
// $base-font-family: 'S-Core', sans-serif;
// $base-font-family: 'droid-sans', sans-serif;
// $input-font-family: 'droid-sans', sans-serif;
// $base-font-family: 'NanumSquareNeo', sans-serif;
// $input-font-family: 'NanumSquareNeo', sans-serif;
$base-font-family: 'Noto Sans KR', sans-serif;
$input-font-family: 'Noto Sans KR', sans-serif;
$input-text-color: $black;

// Font Size
$base-font-size: 15 !default;
$base-font-weight: 300;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{calc(floor(calc($px/$base) * 100) / 100)}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}

@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}

@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}

@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1400px;
$xxl-max-width: 1399px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;