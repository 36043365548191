@import 'components/mixins.scss';

.buttonWrapper {
  width: 100%;
  text-align: center;
  height: 100px;

  .loginButton {
    width: 300px;
    height: 70px !important;
    background-color: $primary !important;
    color: $white !important;
    border: none;
    line-height: 55px;

    &:hover {
      background-color: $light-green !important;
    }
  }
}
